import getConfig from 'next/config';
const { getParts } = require('cache-service/cache/tradingVideoAcademyParts');

const { publicRuntimeConfig } = getConfig();

export const { WORDPRESS_HOST, GOOGLE_CLIENT_ID, FACEBOOK_APP_ID, API_HOST, DOMAIN } =
  publicRuntimeConfig;
export const WP_DOMAIN = 'https://wp.vnrebates.io';
export const API_BASE_URL = process.env.API_URL;

export const EXCHANGE_RATE_USD = 24000;

export const ACCESS_TOKEN = 'app.token';
export const ACCESS_TOKEN_EXPIRED = 'app.token.expired';
export const ACCESS_TOKEN_TYPE = 'app.tokenType';
export const ACCESS_ROLE = 'app.role';
export const ACCESS_USER = 'app.user';
export const AUTH_HEADER = 'Authorization';

export const OAUTH2_REDIRECT_URI =
  process.env.OAUTH2_REDIRECT_URI || 'http://localhost:8000/oauth2/redirect';

export const GOOGLE_AUTH_URL =
  API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL =
  API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;

export const ROLES = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_VNREBATE_ADMIN: 'ROLE_VNREBATE_ADMIN',
  ROLE_VNREBATE: 'ROLE_VNREBATE',
  ROLE_CUSTOMER_CARE: 'ROLE_CUSTOMER_CARE',
  ROLE_VNREBATE_EDITOR: 'ROLE_VNREBATE_EDITOR',
  ROLE_VNREBATE_COLLABORATOR: 'ROLE_VNREBATE_COLLABORATOR',
  ROLE_AGENCY_KPP: 'ROLE_AGENCY_KPP',
  ROLE_AGENCY_CTV: 'ROLE_AGENCY_CTV',
};

export const MODE_ROLE = {
  CREATE_ROLE: 'CREATE_ROLE',
  EDIT_ROLE: 'EDIT_ROLE',
};

export const MODE_VOUCHER = {
  CREATE_VOUCHER: 'CREATE_VOUCHER',
  EDIT_VOUCHER: 'EDIT_VOUCHER',
};

export const MODE_MAIL_TEMPLATE = {
  CREATE_MAIL_TEMPLATE: 'CREATE_MAIL_TEMPLATE',
  EDIT_MAIL_TEMPLATE: 'EDIT_MAIL_TEMPLATE',
};

export const VOUCHER_TYPE = {
  MONEY: { key: 'MONEY', value: 'Số tiền' },
  PERCENT: { key: 'PERCENT', value: 'Phần trăm' },
};

export const LOGIN_STATUS = {
  WAITING_CHECK: 'WAITING_CHECK',
  LOGGED_IN: 'LOGGED_IN',
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
};

export const FIELD_TYPE = {
  TEXTAREA: 'TEXTAREA',
  BOOLEAN: 'BOOLEAN',
  LIST_TEXT: 'LIST_TEXT',
  DATE: 'DATE',
  DATE_TIME: 'DATE_TIME',
  MONTH: 'MONTH',
};

export const FIELD_TYPE_CODE = {
  TEXTAREA_TEST: 'TEXTAREA_TEST',
  BOOLEAN_TEST: 'BOOLEAN_TEST',
  LIST_TEXT_TEST: 'LIST_TEXT_TEST',
  DATE_TEST: 'DATE_TEST',
  DATE_TIME_TEST: 'DATE_TIME_TEST',
  MONTH_TEST: 'MONTH_TEST',
};

export const USER_MEATA_KEY = {
  REGISTER_FREE_BOOK_SUCCESS: 'REGISTER_FREE_BOOK_SUCCESS',
  FIRST_DEPOSIT_BROKER: 'FIRST_DEPOSIT_BROKER',
  REGISTER_FREE_BOOK: 'REGISTER_FREE_BOOK',
  IS_REGISTERED_IB: 'IS_REGISTERED_IB',
  REMAINING_OPEN_CONTACT: 'REMAINING_OPEN_CONTACT',
  TOTAL_OPEN_CONTACT: 'TOTAL_OPEN_CONTACT',
  IS_SUBCRIBED_TELEGRAM_CHANNEL: 'IS_SUBCRIBED_TELEGRAM_CHANNEL',
  IS_SUBCRIBED_TELEGRAM_GROUPCHAT: 'IS_SUBCRIBED_TELEGRAM_GROUPCHAT',
  IS_SUBCRIBED_YOUTUBE: 'IS_SUBCRIBED_YOUTUBE',
  VIDEO_ACADEMY_TEST: 'VIDEO_ACADEMY_TEST',
  IS_BOUGHT_VIDEO_ACADEMY: 'IS_BOUGHT_VIDEO_ACADEMY',
  MONTH_SENT_USER_STATISTIC: 'MONTH_SENT_USER_STATISTIC',
  IS_BOUGHT_VIDEO_SMC: 'IS_BOUGHT_VIDEO_SMC',
  IS_BOUGHT_VIDEO_SUPPLY_DEMAND: 'IS_BOUGHT_VIDEO_SUPPLY_DEMAND',
  IS_BOUGHT_VIDEO_WYCKOFF: 'IS_BOUGHT_VIDEO_WYCKOFF',
  VIDEO_SMC_TEST: 'VIDEO_SMC_TEST',
  VIDEO_SUPPLY_DEMAND_TEST: 'VIDEO_SUPPLY_DEMAND_TEST',
  VIDEO_WYCKOFF_TEST: 'VIDEO_WYCKOFF_TEST',
  IS_BOUGHT_VIDEO_SUPPLY_DEMAND_2: 'IS_BOUGHT_VIDEO_SUPPLY_DEMAND_2',
  IS_BOUGHT_VIDEO_SUPPLY_DEMAND_2_TEST: 'IS_BOUGHT_VIDEO_SUPPLY_DEMAND_2_TEST',
};

export const MODE_USER_META = {
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  EDIT_USER_META: 'EDIT_USER_META',
};

export const GTM_EVENTS = {
  HOME_PAGE_VNRMEMBERSHIP_TELEGRAM_GROUP: 'Homepage_VnrMembership_TelegramGroup',
  FOOTER_TELEGRAM: 'footer-telegram',
  BANNER_INSIDE_POST_REBATES: 'banner-inside-post-rebates',
  BANNER_INSIDE_POST_ACADEMY: 'banner-inside-post-academy',
  BANNER_INSIDE_POST_TELEGRAM: 'banner-inside-post-telegram',
  BANNER_INSIDE_ACADEMY_POST_EVENT_TELEGRAM: 'banner-inside-academy-post-event-telegram',
  BANNER_INSIDE_ACADEMY_POST_TELEGRAM: 'banner-inside-academy-post-telegram',
  HOME_PAGE_TELEGRAM_POST: 'home-page-telegram-post',
  BANNER_DIEU_HUONG_TELEGRAM_BAI_VIET_DESKTOP: 'banner-dieu-huong-telegram-bai-viet-desktop',
  BANNER_DIEU_HUONG_TELEGRAM_BAI_VIET_MOBILE: 'banner-dieu-huong-telegram-bai-viet-mobile',
  MOBILE_TOP_RIGHT_TELEGRAM: 'mobile-top-right-telegram',
  NOTIFY_BOTTOM_LEFT_TELEGRAM: 'notify-bottom-left-telegram',
  NOTIFY_BOTTOM_LEFT_ACADEMY: 'notify-bottom-left-academy',
  NOTIFY_BOTTOM_LEFT_REBATES: 'notify-bottom-left-rebates',
};

export const WP_POST_S3_DIC_ID = 4;

export const GROUP_NAME = {
  FIELD: { key: 'FIELD' },
  COLUMN: { key: 'COLUMN' },
  PRODUCT_TOPIC_BROKER: { key: 'PRODUCT_TOPIC_BROKER' },
};

export const ACTIONS = {
  CREATE_POST: { key: 'CREATE_POST', description: 'Tạo bài viết' },
  VIEW_POST: { key: 'VIEW_POST', description: 'Xem bài viết' },
  BASIC_EDIT_POST: { key: 'BASIC_EDIT_POST', description: 'Cập nhật bài viết' },
  EDIT_OTHER_POST: { key: 'EDIT_OTHER_POST', description: 'Cập nhật bài viết' },
  EDIT_URL: { key: 'EDIT_URL' },
  EDIT_PUBLIC_POST: { key: 'EDIT_PUBLIC_POST', description: 'Publish post' },
  UNDO_PUBLIC_POST: { key: 'UNDO_PUBLIC_POST', description: 'Publish post' },
  PUBLIC_POST: { key: 'PUBLIC_POST', description: 'Publish post' },
  VIEW_REVISION: { key: 'VIEW_REVISION', description: 'Xem revision' },
  CREATE_REVISION: { key: 'CREATE_REVISION', description: 'Tạo revision bài viết' },
  EDIT_REVISION: { key: 'EDIT_REVISION', description: 'Cập nhật revision bài viết' },
  EDIT_OTHER_REVISION: { key: 'EDIT_OTHER_REVISION', description: 'Cập nhật revision bài viết' },
  APPROVAL_REVISION: { key: 'APPROVAL_REVISION', description: 'Duyệt bài viết chưa publish' },
  LIST_REVISOINS: { key: 'LIST_REVISOINS', description: 'Xem danh sách bài viết revision' },
  EDIT_SLUG: { key: 'EDIT_SLUG', description: 'Duyệt bài viết đã publish' },
  EDIT_AUTHOR: { key: 'EDIT_AUTHOR', description: 'Duyệt bài viết đã publish' },
};

export const PERMISSIONS = {
  DEFAULT: {
    key: 'DEFAULT',
    actions: [
      ACTIONS.VIEW_POST,
      ACTIONS.VIEW_REVISION,
      ACTIONS.CREATE_REVISION,
      ACTIONS.EDIT_REVISION,
    ],
  },
  CREATE_POST: { key: 'CREATE_POST', actions: [ACTIONS.CREATE_POST] },
  BASIC_EDIT_POST: { key: 'BASIC_EDIT_POST', actions: [ACTIONS.BASIC_EDIT_POST] },
  EDIT_OTHER_POST: { key: 'EDIT_OTHER_POST', actions: [ACTIONS.EDIT_OTHER_POST] },
  PUBLIC_POST: { key: 'PUBLIC_POST', actions: [ACTIONS.PUBLIC_POST] },
  EDIT_POST_PUBLIC: {
    key: 'EDIT_POST_PUBLIC',
    actions: [ACTIONS.UNDO_PUBLIC_POST, ACTIONS.PUBLIC_POST],
  },
  EDIT_PUBLIC_POST: { key: 'EDIT_PUBLIC_POST', actions: [ACTIONS.EDIT_PUBLIC_POST] },
  APPROVAL_REVISION_POST: {
    key: 'APPROVAL_REVISION_POST',
    actions: [ACTIONS.APPROVAL_REVISION, ACTIONS.LIST_REVISOINS, ACTIONS.EDIT_OTHER_REVISION],
  },
  EDIT_SLUG: {
    key: 'EDIT_SLUG',
    actions: [ACTIONS.EDIT_SLUG],
    description: 'Duyệt bài viết đã publish',
  },
  EDIT_AUTHOR: {
    key: 'EDIT_AUTHOR',
    actions: [ACTIONS.EDIT_AUTHOR],
    description: 'Duyệt bài viết đã publish',
  },
  INSIDE_POST_TELEGROUP: 'Inside_Post_Telegroup',
  INSIDE_POST_KHOA_4: 'Inside_Post_Khoa_4',
  ACADEMY_BAIHOC_TELEGROUP: 'Academy_Baihoc_Telegroup',
  ACADEMY_BAIHOC_KHOA_4: 'Academy_Baihoc_Khoa_4',
  HOME_PAGE_PTKT_TELEGRAM_GROUP: 'Homepage_PTKT_TelegramGroup',
  DESKTOP_BAI_VIET_TOP_TELEGROUP: 'Desktop_Bai_viet_Top_TeleGroup',
  DESKTOP_BAI_VIET_TOP_KHOA_4: 'Desktop_Bai_viet_Top_Khoa_4',
  DESKTOP_CATEGORY_TOP_TELEGROUP: 'Desktop_Category_Top_TeleGroup',
  DESKTOP_CATEGORY_TOP_KHOA_4: 'Desktop_Category_Top_Khoa_4',
  MOBILE_TOP_BAI_VIET_TELEGROUP: 'Mobile_top_Bai_viet_Telegroup',
  MOBILE_TOP_BAI_VIET_KHOA_4: 'Mobile_top_Bai_viet_Khoa_4',
  MOBILE_TOP_RIGHT_TELEGROUP: 'Mobile_Top_Right_TeleGroup',
  MOBILE_TOP_RIGHT_KHOA_4: 'Mobile_Top_Right_Khoa_4',
  DESKTOP_BOTTOM_LEFT_TELEGROUP: 'Desktop_Bottom_Left_TeleGroup',
  DESKTOP_BOTTOM_LEFT_KHOA_4: 'Desktop_Bottom_Left_Khoa_4',
  BUTTON_HOMEPAGE_TELEGROUP: 'Button_Homepage_Telegroup',
};

export const GTM_EVENTS_COLOR = {
  FOOTER_TELEGRAM: 'footer-telegram',
  Inside_Post_Telegroup: '#cccccc',
  Inside_Post_Khoa_4: '#cccccc',
  Academy_Baihoc_Telegroup: '#f4cccc',
  Academy_Baihoc_Khoa_4: '#f4cccc',
  Homepage_PTKT_TelegramGroup: '#ffe599',
  Desktop_Bai_viet_Top_TeleGroup: '#fff2cc',
  Desktop_Bai_viet_Top_Khoa_4: '#fff2cc',
  Desktop_Category_Top_TeleGroup: '#a3a1d8',
  Desktop_Category_Top_Khoa_4: '#a3a1d8',
  Mobile_top_Bai_viet_Telegroup: '#ea9999',
  Mobile_top_Bai_viet_Khoa_4: '#ea9999',
  Mobile_Top_Right_TeleGroup: '#a4c2f4',
  Mobile_Top_Right_Khoa_4: '#a4c2f4',
  Desktop_Bottom_Left_TeleGroup: '#d9ead3',
  Desktop_Bottom_Left_Khoa_4: '#d9ead3',
  BUTTON_HOMEPAGE_TELEGROUP: 'Button_Homepage_Telegroup',
};

export const WP_POST_S3_BANNER_ID = 2;

export const CV_AVATAR_S3_FOLDER_ID = 3;

export const CUSTOMER_REVIEW_IMAGE_S3_FOLDER_ID = 4;

export const TRADER_DISCUSSION_IMAGE_S3_FOLDER_ID = 5;

export const WP_POST_S3_YOUTUBE_THUMBNAIL = 6;

export const CUSTOMER_REVIEW_STATUS = {
  PUBLIC: { key: 'PUBLIC', value: 'Public' },
  PRIVATE: { key: 'PRIVATE', value: 'Private' },
};

export const CONFIG_YOUTUBE_TYPE = {
  VIDEO: 'VIDEO',
  PLAYLIST: 'PLAYLIST',
  USER_UPLOAD: 'USER_UPLOAD',
};

export const VIDEO_TRADING_ACADEMY_PART_CODE = getParts('ALL');

export const VIDEO_ACADEMY_SLUG = 'khoa-hoc-price-action-volume-master';

export const VIDEO_ACADEMY_PRICE = 200;
export const PERCENT_REF_PROMO = 0.2;
export const PERCENT_OWN_PROMO = 0.3;
export const LAUNCHING_DATE = '2022-07-25 19:00';
export const PROMO_CODE_VALID_THROUGH_DATE = '2022-09-01 00:00';

export const PERCENT_COMMISSION_AGENCY = 0.15;
export const PERCENT_COMMISSION_SALE = 0.026;

export const servicesOptions = [
  {
    code: 'Khóa Video 1 - VSA & Volume Master (200$)',
    name: {
      vi: 'Các Khóa học Trading "MIỄN PHÍ"',
      en: '"FREE" Trading Courses',
      cn: '“免费”交易课程',
    },
    link: '/trading-academy/hoc-price-action-a-z',
  },
  {
    code: 'Khóa Video 2 - SMC tinh gọn (200$)',
    name: {
      vi: `Khóa video / <a href="https://shopee.vn/vnrebates" target="_blank">Flashcard</a> "Trading chuyên sâu" <br /> 
      <i>(<a href="https://vnrebates.io/trading-academy-video/khoa-hoc-smc-tinh-gon" target="_blank">SMC</a>, <a href="https://vnrebates.io/trading-academy-video/khoa-hoc-snd-2.0" target="_blank">SND</a>, <span>ICT</span>, <a href="https://vnrebates.io/trading-academy-video/khoa-hoc-wyckoff-2" target="_blank">Wyckoff 2.0</a>, <a href="https://vnrebates.io/trading-academy-video/khoa-hoc-price-action-volume-master" target="_blank">VSA & Volume</a>)</i>`,
      en: 'Video Course 2 - Simple SMC ($200)',
      cn: '视频课程 2 - 精益SMC（200 美元)',
    },
    // link: '/trading-academy-video/khoa-hoc-smc-tinh-gon'
  },
  {
    code: 'Khóa Video 3 - Supply & Demand 2.0 (200$)',
    name: {
      vi: 'Chương trình Training "Học 1 lần, Trade 1 đời"',
      en: 'Video Course 3 - Supply & Demand 2.0 (200$)',
      cn: '视频课程 3 - 供需课程（200 美元)',
    },
    // link: '/trading-academy-video/khoa-hoc-snd-2.0',
  },
  {
    code: 'Dịch vụ Hoàn phí',
    name: {
      vi: 'Hoàn tiền Forex/Crypto lên tới 95%',
      en: 'Cashbach Rebate Service (up to 10USD/lot)',
      cn: '高达 10USD/lot 回扣服务',
    },
    link: '/dich-vu-forex-rebates-hoan-phi-forex-la-gi',
  },
];

export const marketOptions = [
  {
    code: 'Forex',
    name: {
      vi: 'Forex',
      en: 'Forex',
      cn: '外汇',
    },
    link: '/forex-broker',
  },
  {
    code: 'Crypto',
    name: {
      vi: 'Crypto',
      en: 'Crypto',
      cn: '加密货币',
    },
    link: '/crypto-broker',
  },
  {
    code: 'Chứng khoán',
    name: {
      vi: 'Chứng khoán',
      en: 'Securities',
      cn: '股票',
    },
    link: '/cong-ty-chung-khoan',
  },
  {
    code: 'Hàng hóa phái sinh',
    name: {
      vi: 'Hàng hóa',
      en: 'Merchandise',
      cn: '商品贸易公司',
    },
    link: '/cong-ty-giao-dich-hang-hoa',
  },
];
export const BROKERS_WITH_LICENSE = [
  {
    code: 'IC_MARKETS',
    licenseOrder: 1,
  },
  {
    code: 'DUPOIN',
    licenseOrder: 2,
  },
  {
    code: 'EXNESS',
    licenseOrder: 3,
  },
  {
    code: 'FXGT',
    licenseOrder: 4,
  },
  {
    code: 'TMGM',
    licenseOrder: 5,
  },
  {
    code: 'JustMarkets',
    licenseOrder: 6,
  },
  {
    code: 'ONEBID',
    licenseOrder: 7,
  },
  {
    code: 'Fullerton Markets',
    licenseOrder: 8,
  },
];

export const IGNORE_CATE_AUTHOR_RANKING = [
  {
    id: 3739,
    name: 'Phân tích &amp; nhận định',
    slug: 'phan-tich-nhan-dinh',
  },
  {
    id: 3995,
    name: 'Tỷ giá bảng Anh',
    slug: 'ty-gia-bang-anh',
  },
  {
    id: 3994,
    name: 'Tỷ giá đô la Úc',
    slug: 'ty-gia-dola-uc',
  },
  {
    id: 3993,
    name: 'Tỷ giá Euro',
    slug: 'ty-gia-euro',
  },
  {
    id: 3992,
    name: 'Tỷ giá USD',
    slug: 'ty-gia-usd',
  },
  {
    id: 3996,
    name: 'Tỷ giá Yên Nhật',
    slug: 'ty-gia-yen-nhat',
  },
];

export const IMAGE_FLASH_CARD = {
  'nen-nhat': { 
    title: 'Nến Nhật',
    picture: 'https://agileops-gafv-dev-videos.s3.amazonaws.com/thumbnail/nen%20nhat.png',
    link: 'https://shopee.vn/Flashcard-N%E1%BA%BFn-Nh%E1%BA%ADt-V%C3%A0-Bi%E1%BB%83u-%C4%90%E1%BB%93-VnRebates-H%E1%BB%8Dc-B%E1%BA%A5t-Bi%E1%BA%BFn-%C4%90%E1%BB%83-%E1%BB%A8ng-V%E1%BA%A1n-Bi%E1%BA%BFn--i.1058780730.24776291054?sp_atk=fd0c834c-8e50-4006-acb3-a5cd3eadac1e&xptdk=fd0c834c-8e50-4006-acb3-a5cd3eadac1e'
  },
  'khoa-hoc-smc-tinh-gon': {
    title: 'SMC tinh gọn',
    picture: '/images/flash-card/smc.png',
    link: 'https://shopee.vn/Flash-card-t%C3%A0i-ch%C3%ADnh-02-SMC-Tinh-g%E1%BB%8Dn-i.1058780730.19292327190'
  },
  'khoa-hoc-supply-demand': {
    title: 'Supply & Demand 2.0',
    picture: '/images/flash-card/snd.png',
    link: 'https://bom.so/web-snd-20'
  },
  'khoa-hoc-price-action-volume-master': {
    title: 'VSA & Volume Master',
    picture: '/images/flash-card/vsa.png',
    link: 'https://shopee.vn/Flash-card-t%C3%A0i-ch%C3%ADnh-01-VSA-Volume-Master-B%C6%A1i-c%C3%B9ng-c%C3%A1-m%E1%BA%ADp-i.1058780730.20884482055?xptdk=3a49caf1-8529-41d2-9290-f7c3ea380f79'
  },
  'khoa-hoc-wyckoff-2': {
    title: 'Wyckoff 2.0',
    picture: '/images/flash-card/wyckoff.png',
    link: 'https://shopee.vn/Flashcard-T%C3%A0i-ch%C3%ADnh-04-Wyckoff-2.0-i.1058780730.23258630829'
  },
  'khoa-hoc-ict': {
    title: 'ICT concept',
    picture: '/images/flash-card/ict.png',
    link: 'https://shopee.vn/Flashcard-t%C3%A0i-ch%C3%ADnh-05-ICT-concept-i.1058780730.19385516396'
  },
  'all': {
    title: 'Combo 5 bộ Flashcard',
    picture: '/images/flash-card/all.png',
    link: 'https://shopee.vn/Combo-05-(SMC-SnD-2.0-ICT-Concept-VSA-Wyckoff-2.0)-5-b%E1%BB%99-th%E1%BA%BB-Flashcard-T%E1%BA%B7ng-khoen-m%C3%B3c-i.1058780730.28910172948?xptdk=f9e69443-a844-46b5-b41f-d99101d7d618'
  },
  'khoa-hoc-snd-2.0': {
    title: 'Supply & Demand 2.0',
    picture: '/images/flash-card/snd.png',
    link: 'https://bom.so/web-snd-20',
    isHideHomepage: true
  },
}
